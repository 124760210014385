.skeleton {
    border-radius: 1.2rem;
    background: linear-gradient(to right, rgba(255, 255, 255, .1), transparent);
    backdrop-filter: blur(1rem);
    padding: 1.2rem;
    width: 18.5rem;
    height: 16.5rem;
    flex-shrink: 0;
    margin-right: 1rem;
}

.skeleton:last-child{
    margin-right: 0;
}

.skeleton__header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.skeleton__header__icon {
    width: 2.4rem;
    height: 2.4rem;
    border-radius: 50%;
    background: rgba(16, 6, 24, .5);
    animation: skeleton 1s infinite ease-in-out alternate-reverse;
}

.skeleton__header__info__title {
    margin: 0;
    line-height: 1em;
    font-size: 1.6rem;
    font-weight: bold;
    background: rgba(16, 6, 24, .5);
    width: 5rem;
    animation: skeleton 1s infinite ease-in-out alternate-reverse;
    border-radius: .4rem;
}

.skeleton__header__info {
    margin-left: 1rem;
}

.skeleton__header__info__name {
    margin: 0;
    line-height: 1em;
    font-weight: normal;
    font-size: 1rem;
    width: 10rem;
    background: rgba(16, 6, 24, .5);
    animation: skeleton 1s infinite ease-in-out alternate-reverse;
    border-radius: .4rem;
}

.skeleton__details {
    border-radius: 1.2rem;
    padding: 1rem;
    margin-top: 2rem;
    background: rgba(16, 6, 24, .5);
    animation: skeleton 1s infinite ease-in-out alternate-reverse;
}

.skeleton__details__value {
    margin: 0;
    font-size: 1.2rem;
    font-weight: bold;
}

.skeleton__details__rate {
    margin: 0;
    font-size: 1.2rem;
    font-weight: bold;
}

@keyframes skeleton {
    from {
        opacity: .1;
    } to {
        opacity: 1;
    }
}