.header__menu__wallet {
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, .1);
    padding: .6rem .8rem;
    backdrop-filter: blur(2rem);
    border-radius: 5rem;
    transition: .4s linear;
    position: relative;
}

.header__menu__wallet:hover,
.header__menu__wallet[data-enabled="true"] {
    background: rgba(255, 255, 255, 0.2);
    cursor: pointer;
}

.header__menu__wallet[data-enabled="true"] .header__menu__wallet__dropdown,
.header__menu__button[data-enabled="true"] .header__menu__wallet__dropdown{
    opacity: 1;
    height: 5.8rem;
}

.header__menu__wallet__name {
    padding: 0 .5rem;
    color: white;
    font-size: 1rem;
}

.header__menu__wallet__icon {
    width: 1.8rem;
}

.header__menu__wallet__dropdown {
    position: absolute;
    top: 110%;
    width: 100%;
    height: auto;
    background: rgba(34, 9, 50, 0.9);
    padding: .3rem;
    backdrop-filter: blur(2rem);
    border-radius: 1rem;
    transition: .4s;
    overflow: hidden;
    height: 0;
    opacity: 0;
}

.header__menu__wallet__dropdown__list {
    margin: 0;
    list-style: none;
    padding: 0 .4rem;
}

.header__menu__wallet__dropdown__list__item {
    color: #ffffff;
    padding: .3rem .6rem;
    margin: .3rem 0;
    transition: .4s;
}

.header__menu__wallet__dropdown__list__item:hover {
    background: rgba(169, 90, 183, 0.1);
    border-radius: .5rem;
    color: #9E85F1;
}

.header__menu__button {
    border: none;
    border-radius: .8rem;
    background: rgba(158, 133, 241, .2);
    padding: 1rem 1.5rem;
    font-size: 1rem;
    font-weight: bold;
    color: #9E85F1;
    cursor: pointer;
    position: relative;
    transition: background .4s;
}

.header__menu__button .header__menu__wallet__dropdown {
    font-weight: 400;
    width: 100%;
    right: 0;
    height: max-content !important;
}

@media (max-width: 1024px) {
    .header__menu__button {
        padding: .7rem 1.2rem;
    }
}

.header__menu__button:hover,
.header__menu__button[data-enabled="true"] {
    background: rgba(158, 133, 241, .4);
}

.dropdown__button {
    border: none;
    border-radius: .6rem;
    background: rgba(94, 227, 226, .2);
    padding: .5rem .6rem;
    margin: .3rem 0;
    text-align: center;
    font-size: 1rem;
    font-weight: bold;
    color: #5EE3E2;
    cursor: pointer;
    position: relative;
    transition: background .4s;
}

.dropdown__button:hover {
    background: rgba(94, 227, 226, .4);
}