.coincard {
    border-radius: 1.2rem;
    background: rgba(255, 255, 255, .1);
    backdrop-filter: blur(1rem);
    padding: 1.2rem;
    width: 18.5rem !important;
    height: 16.5rem;
    flex-shrink: 0;
    margin-right: 1rem;
}

.coincard:last-child{
    margin-right: 0;
}

.coincard__header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.coincard__header__icon {
    width: 2.4rem;
}

.coincard__header__info__title {
    margin: 0;
    line-height: 1em;
    font-size: 1.6rem;
    font-weight: bold;
    color: white;
    width: 50%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.coincard__header__info {
    margin-left: 1rem;
    width: 100%;
}

.coincard__header__info__name {
    margin: 0;
    line-height: 1em;
    color: white;
    font-weight: normal;
    font-size: 1rem;
}

.coincard__details {
    background: rgba(16, 6, 24, .5);
    border-radius: 1.2rem;
    padding: 1rem;
    margin-top: 2rem;
}

.coincard__details__value {
    margin: 0;
    color: #fff;
    font-size: 1.2rem;
    font-weight: bold;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.coincard__details__rate {
    margin: 0;
    color: #5EE3E2;
    font-size: 1rem;
    font-weight: bold;
}

.coincard__details__rate.low {
    color: #F13535;
}

.coincard__details__rate.up {
    color: #5EE3E2;
}