.skeleton {
    height: 4rem;
    margin-bottom: .5rem;
    background: rgba(60, 52, 65, 0.1);
    backdrop-filter: blur(1rem);
    border-radius: .4rem;
    transition: .4s;
    display: flex;
    align-items: center;
    justify-content: space-between;
    animation: blink 1s infinite alternate-reverse;
}

.skeleton:last-child {
    margin-bottom: 0;
}

@keyframes blink {
    from {
        opacity: 0;
    } to {
        opacity: 1;
    }
}