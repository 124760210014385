.info {
    background: rgba(158, 133, 241, .05);
    padding: .5rem;
    border-radius: .5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    flex-shrink: 0;
    margin-top: .5rem;
    position: relative;
}

.info__text {
    font-weight: bold;
    text-transform: uppercase;
    background: transparent;
    border: none;
    color: #ffffff;
    display: block;
    width: 80%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-left: 1rem;
}

.info__text:focus {
    outline: none;
}

.info__button {
    width: 3rem;
    height: 3rem;
    background: rgba(37, 16, 56, .1);
    border: .1rem solid #9E85F1;
    border-radius: .5rem;
    transition: background .4s linear;
}

.info__button:hover {
    background: rgba(37, 16, 56, .7);
}

.info__button__icon {
    width: 60%;
}

.info__feedback {
    display: block;
    position: absolute;
    right: 0;
    top: -3rem;
    background: #9E85F1;
    border-radius: .3rem;
    padding: .5rem 1rem;
    animation: fadeIn .4s forwards;
    font-weight: bold;
    color: #ffffff;
}

.info__feedback::after {
    content: '';
    display: block;
    width: 1rem;
    height: 1rem;
    position: absolute;
    bottom: -.25rem;
    background: #9E85F1;
    right: 1rem;
    transform: rotate(45deg);
}

.info__feedback[disabled] {
    display: none;
}

@keyframes fadeIn {
    from {
        transform: translateY(20%);
        opacity: 0;
    } to {
        transform: translateY(0);
        opacity: 1;
    }
}