.simplebutton {
    border: none;
    border-radius: .8rem;
    background: rgba(158, 133, 241, .2);
    padding: 1rem 1.5rem;
    display: block;
    width: 100%;
    margin-top: 1rem;
    font-size: 1rem;
    font-weight: bold;
    color: #9E85F1;
    cursor: pointer;
    transition: background .4s;
}

.simplebutton:hover {
    background: rgba(158, 133, 241, .4);
}

.simplebutton[disabled] {
    background: rgba(16, 6, 24, .5);
    animation: skeleton 1s infinite ease-in-out alternate-reverse;
}

@keyframes skeleton {
    from {
        opacity: .1;
    } to {
        opacity: 1;
    }
}