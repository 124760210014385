.input__label {
    color: #9E85F1;
    font-size: .9rem;
    margin-bottom: .6rem;
    position: relative;
    z-index: 30;
}

.input__field {
    background: rgba(158, 133, 241, .05);
    padding: 1rem;
    border-radius: .5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: .1rem solid #9E85F1;
    position: relative;
    z-index: 1;
}

.input__field__coin {
    color: #ffffff;
    font-weight: bold;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
}

.input__field__coin__tag {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    user-select: none;
}

.input__field__coin__icon {
    width: 1.5rem;
    margin-right: .5rem;
}

.input__field__coin__arrow {
    right: 0;
    width: .4rem;
    margin-left: .6rem;
    transform: rotate(90deg);
}

.input__field__item {
    background: transparent;
    border: none;
    color: #ffffff;
    font-weight: bold;
    text-align: end;
    width: 100%;
}

.input__field__item:focus {
    outline: none;
}

.input__field__selector {
    position: absolute;
    top: 3rem;
    left: 0;
}

.input__field__selector[disabled] {
    display: none;
}

.input__label__info {
    margin-left: .5rem;
    display: inline-block;
    transform: translateY(-.05rem);
    width: .8rem;
    position: relative;
}

.input__label__info:hover .input__label__info__bubble {
    opacity: 1;
    transform: translateX(-.75rem) translateY(-0%);
}

.input__label__info__bubble {
    display: block;
    min-width: 10rem;
    position: absolute;
    top: 2rem;
    border-radius: .5rem;
    background: rgba(16, 6, 24, 1);
    transform: translateX(-.75rem) translateY(-10000%);
    padding: 1rem;
    font-size: .7rem;
    text-align: center;
    transition: opacity .4s;
    opacity: 0;
}

.input__label__info__bubble::before {
    content: '';
    display: block;
    position: absolute;
    width: .5rem;
    height: .5rem;
    background: rgba(16, 6, 24, 1);
    top: -.25rem;
    transform: rotate(45deg);
}