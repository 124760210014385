.input__label {
    color: #9E85F1;
    font-size: .9rem;
    margin-bottom: .6rem;
}

.input__field {
    background: rgba(158, 133, 241, .05);
    padding: 1rem;
    border-radius: .5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: .1rem solid #9E85F1;
    transition: border .4s linear, background .4s linear;
    position: relative;
}

.input__field__icon {
    width: 1.2rem;
    margin-right: 1rem;
}

.input__field__icon__media {
    width: 100%;
}

.input__field__item {
    border: none;
    width: 100%;
    font-weight: 500;
    color: #ffffff;
    text-align: start;
    background: transparent;
}

.input__field__item:focus {
    outline: none;
}

.invalid {
    border: .1rem solid #ff0000;
    background: rgba(158, 5, 5, .05);
}

.invalid::after {
    position: absolute;
    content: "E-mail inválido";
    padding: .5rem 1rem;
    background: #a11919;
    border-radius: .4rem;
    font-weight: bold;
    color: #ffffff;
    bottom: -1rem;
    right: -1.5rem;
}


