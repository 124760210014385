.summary {
    font-weight: 500;
    font-size: .9rem;
    color: #ffffff;
}

.summary__total,
.summary__fee,
.summary__coinPrice {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: .4rem;
}

.summary__coinPrice__value,
.summary__fee__value,
.summary__total__value {
    font-weight: bold;
    text-align: end;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex: .8
}