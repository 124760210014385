.header {
    display: block;
    text-align: center;
}

.header__title {
    font-weight: bold;
    color: #9E85F1;
}

.header__details {
    color: #9E85F1;
}

@media (max-width: 1024px) {
    .header__details {
        font-size: .8rem;
    }
}

.block {
    width: 7rem;
    height: 7rem;
    border: .1rem solid #5EE3E2;
    border-radius: 50%;
    background: rgba(94, 227, 226, .1);
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem auto;
}

.block__media {
    width: 90%;
    animation: rotation 20s infinite linear;
}

.transaction {
    text-align: center;
}

.transaction__text {
    color: #9E85F1;
}

@keyframes rotation {
    to {
        transform: rotate(360deg);
    }
}